import { 
    Box, 
  }
  //@ts-ignore
  from "@enerBit/base"; 

  const ServiceCard = ({container, icon, background}) => { 
    return (
      <Box className="Service-card-main" sx={{background: background}}>
        <Box sx={{color: "white", pb: "10px", pl: "10px", position: "absolute", bottom:"0", left: "0"}}>
          {container}
        </Box>
        <Box className="Service-card-secondary"/>  
        <Box className="Service-card-third" />  
        <Box className="Service-card-fourth" />  
        <Box className="Service-card-fifth">
          {icon}
        </Box>
      </Box>
    );
  };
  
  export default ServiceCard;
  