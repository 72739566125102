import { styled } from "@mui/system";
import { Typography, LinearProgress, Box, IconButton } from "@mui/material";

import { DeleteOutlineOutlinedIcon } from "../helpers/iconsMaterial";

type Props = {
  fileInfo?: {
    name: string;
    size: number;
  };
  progress: number;
  isSuccess: Boolean;
  onClearFile: () => void;
  typeFile?: string;
};

export const CardPdf = ({
  fileInfo,
  progress,
  isSuccess,
  onClearFile,
  typeFile,
}: Props) => {
  const { name, size } = fileInfo || {};
  return (
    <Card>
      <CardBody>
        <SquareFile>{typeFile ? typeFile.toUpperCase() : "PDF"}</SquareFile>
        <Box sx={{ width: "100%", textAlign: "start" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            {name ? name : "Archivos pdf"}
          </Typography>
          {!isSuccess && typeFile !== "csv" ? (
            <LinearProgress
              sx={{ borderRadius: "5px", height: "8px", width: "100%" }}
              color="secondary"
              variant="determinate"
              value={progress}
            />
          ) : (
            <Typography sx={{ fontSize: "12px", color: "#667085" }}>
              {size ? size + " kb" : ""}
            </Typography>
          )}
        </Box>
        <IconContainer onClick={onClearFile}>
          <IconButton color="error" component="label">
            <DeleteOutlineOutlinedIcon sx={{ fontSize: "22px" }} />
          </IconButton>
        </IconContainer>
      </CardBody>
    </Card>
  );
};

//------------Styled-----------
const Card = styled("div")({
  backgroundColor: "#F2F4F7",
  borderRadius: "16px",
  height: "82px",
  margin: "10px 0",
  width: "100%",
});

const CardBody = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  padding: "16px",
});

const SquareFile = styled("div")({
  alignItems: "center",
  backgroundColor: "#F04438",
  borderRadius: "4px",
  color: "#fff",
  display: "flex",
  fontSize: "12px",
  fontWeight: "bold",
  justifyContent: "center",
  height: "50px",
  marginRight: "16px",
  width: "50px",
});

const IconContainer = styled("div")({
  alignItems: "center",
  backgroundColor: "#FEE4E2",
  borderRadius: "12px",
  display: "flex",
  height: "32px",
  justifyContent: "center",
  marginLeft: "10px",
  width: "32px",
});
