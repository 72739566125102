import moment from "moment";
moment.locale("es");
//Funcion para obtener el dia de una fecha
export function formatDay(date: string) {
  return moment(date).format("DD");
}

export function formatDateMonthWithName(date: string) {
  return moment(date).format("MMMM/DD/YYYY");
}
