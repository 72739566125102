import { 
    Box,
    CancelIcon,
    theme,
    Alert,
    CheckCircleIcon,
    WarningIcon,
    InfoIcon,
  }
  //@ts-ignore
  from "@enerBit/base";
  import {forwardRef, useImperativeHandle, useState } from "react";
  //@ts-ignore
  import { customAlertProps } from "../models/customPropsComponents";

  const CustomAlert =({severity, text, sx, onClose}:customAlertProps) => {
    let background = "";
    const caseIcon = () => {
      var icon;
      switch(severity){
        case "error":
          icon = <CancelIcon sx={{color: "#f04438"}} />;
          background = "#fee4e2";
          break;
        case "success":
          icon = <CheckCircleIcon sx={{color: "#13b76a"}} />; 
          background = "#d1fadf";
          break;
        case "warning":
          icon = <WarningIcon sx={{color: "#f04438"}} />; break;
        case "info":
          icon = <InfoIcon sx={{color: "#f04438"}} />; break;
      }
      return icon;
    } 
    
    return (
      <Alert 
        sx={{...sx, backgroundColor: background }}
        //@ts-ignore
        onClose={() => onClose()} 
        icon={caseIcon()} 
        severity={severity}
      >
        <Box color={theme.palette.neutral["900"]}>{text}</Box>
      </Alert>
    );
  };
  
  export default CustomAlert;
  