import { UploadOutlinedIcon } from "../helpers/iconsMaterial";
import { styled } from "@mui/system";
import { Button } from "@mui/material";
import { useDropzone } from "react-dropzone";

type InputProps = {
  active: Boolean;
};

type Props = {
  onDrop: () => void;
  typeFile?: string;
};

const optionFiles = {
  img: "Imagen",
  csv: "Archivo",
  xlsx: "Archivo",
};

export const DragZone = ({ onDrop, typeFile }: Props) => {
  const acceptOptions = () => {
    switch (typeFile) {
      case "img":
        return { "image/*": [] };
      case "csv":
        return { "text/csv": [".csv"] };
      case "xlsx":
        return { "text/xlsx": [".xlsx"] };
      default:
        return {};
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptOptions(),
  });

  return (
    <FileUploader onDrop={onDrop} {...getRootProps()} active={isDragActive}>
      Arrastra aquí o
      <Button
        sx={{ marginLeft: "10px" }}
        startIcon={<UploadOutlinedIcon />}
        variant="contained"
        color="secondary"
        component="label"
      >
        <input type="file" hidden {...getInputProps()} />
        Adjunta {typeFile ? optionFiles[typeFile] : "pdf"}
      </Button>
    </FileUploader>
  );
};

const FileUploader = styled("div")<InputProps>(({ active }) => ({
  alignItems: "center",
  backgroundColor: active ? "rgb(0, 0, 0, 0.1)" : "",
  border: "2px dashed #ccc",
  color: "#667085",
  cursor: "pointer",
  display: "flex",
  height: "100px",
  justifyContent: "center",
  margin: "10px 0",

  "&:hover": {
    backgroundColor: "rgb(0, 0, 0, 0.1)",
  },
}));
