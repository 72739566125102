import axios from "axios";
import { api } from "../../src/auth/interceptorApi";
import { getRefreshToken } from "../common/helpers/sessionStorage";

export const tokenApi = axios.create({
	baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

tokenApi.interceptors.request.use((config) => {
	if (config.headers) {
		config.headers["Content-Type"] = "application/x-www-form-urlencoded";
		config.headers.Accept = "application/json";
	}
	return config;
});

export const refreshToken = async (): Promise<string | undefined> => {
	try {
		const refresh = getRefreshToken();

		const body = {
			refresh_token: refresh,
		};

		const headers = {
			Accept: "application/json",
			"Content-Type": "application/json",
		};

		const { data } = await api.post("/auth/token/refresh", body, {
			headers,
		});

		return data.access_token;
	} catch (error) {
		console.log(error);
	}
};
