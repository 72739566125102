import { 
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    HighlightOffIcon
  }
  //@ts-ignore
  from "@enerBit/base";
  import {forwardRef, useImperativeHandle, useState } from "react"; 
  import { customModalProps } from "../models/customPropsComponents";

  const CustomModal = forwardRef(({dialogContent, dialogActions, onClose}:customModalProps, ref) => {
    const [open, setOpen] = useState(false);
  
    useImperativeHandle(ref, () => ({
      changeModal() {
        setOpen(open ? false : true)
      },
    }));
  
    return (
      <Dialog  PaperProps={{
        style: { borderRadius: "14px", width: "100%" }
      }} fullWidth maxWidth="xs" open={open}>
        <Box sx={{padding: "0px 20px 20px 20px"}}>
          <DialogTitle sx={{paddingBottom: "0px"}}>
            <IconButton sx={{float: "right"}} onClick={() => {
              setOpen(false)
              //@ts-ignore
              onClose()
            }}>
              <HighlightOffIcon sx={{fontSize: "35px", color: "#8876af"}} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{width: "100%", paddingBottom: "0px"}}>
           {dialogContent}
          </DialogContent>
          <DialogActions sx={{ display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
            {dialogActions}
          </DialogActions>
        </Box>
      </Dialog>
    );
  });
  
  export default CustomModal;
  