export const serviceName =  {
  "digital-energy": "Energía digital",
  "electricity-supply": "Energía tradicional",
  "billerbit-street-light": "Alumbrado público",
  "cnc-distributed-generation": "Autogeneración CNC",
  "cnc-distributed-energy": "Autogeneración CNC",
  "enerBit-electricity-supply": "Servicios enerBit",
}  

export const translateServiceName = (value) => {
  return serviceName[value];
}

export const getMagicalParamUrl = () => {
  const url = new URL(window.location.href.toString().replace("/#", "")); 
  const queryParams = new URLSearchParams(url.search);  
  const param = queryParams.get("q"); 
  return param;
}