import jwt_decode from "jwt-decode";
import type { JwtDecode } from "../models/jwt";
import { getAcessToken } from "./sessionStorage";

export const decodedJwt = () => {
	const accessToken = getAcessToken();
	const decoded = jwt_decode(accessToken);
	return decoded;
};

export const verifyJwt = (): boolean => {
	const { exp } = decodedJwt() as JwtDecode;
	return exp > Date.now() / 1000;
};
