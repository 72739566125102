import type { AuthInfo } from "../models/auth";

export const getAuthInfo = () => {
  const authInfo: AuthInfo = JSON.parse(localStorage.getItem("authInfo"));
  return authInfo;
};

export const getAcessToken = () => {
  if (getAuthInfo()) {
    const accessToken = getAuthInfo().access_token;
    return accessToken;
  }
};

export const getRefreshToken = () => {
  const { refresh_token } = getAuthInfo();
  return refresh_token;
};

export const updateAccessToken = (accessToken: string) => {
  const authInfo = {...getAuthInfo()}
  authInfo.access_token = accessToken;
  localStorage.setItem("authInfo", JSON.stringify(authInfo));
};

export const cleanSessionStorage = () => {
  localStorage.removeItem("authInfo");
};
