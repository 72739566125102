import { cleanSessionStorage } from "../../common/helpers/sessionStorage";
import { verifyJwt } from "../helpers/decodeJwt";

export { PrivateRoute };

function PrivateRoute({ children }) {
  const isTokenExpired = verifyJwt();
  if (!isTokenExpired) {
    cleanSessionStorage();
    return;
  }
  return children;
}
