//@ts-ignore
import {
  Drawer,
  Box,
  styled,
  //@ts-ignore
} from "@mui/material";

import { 
  ArrowCircleRightOutlinedIcon,
} from "../../common/helpers/iconsMaterial";

const DialogInformation = ({ isDrawerOpen, setIsDrawerOpen, content, onCloseAction, sx }) => {
  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false); 
      }}
      PaperProps={{
        sx: {
          flexDirection: "initial",
          display: " flex",
          justifyContent: "center",
          width: "40rem",
          "@media (max-width: 768px)": { width: "95%" },
          ...sx
        },
      }}
    >
      <Box
        role="presentation"
        sx={{ overflowX:"hidden", overflowY: "auto", width: "100%"}}
      >
        <ArrowCircleRightOutlinedIconStyled
          fontSize="large"
          sx={{ fontSize: "3rem", top: "50%" }}
          color="primary"
          className="-essdetail"
          onClick={() => {setIsDrawerOpen(false); onCloseAction()}}
        />

        {content}

      </Box>
    </Drawer>
  );
};

export default DialogInformation;

const ArrowCircleRightOutlinedIconStyled = styled(ArrowCircleRightOutlinedIcon)`
  background-color: white;
  position: fixed !important;
  transform: translate(-24px) !important;
  cursor: pointer !important;
  border-radius: 2rem;
`;

const DrawerStyled = styled(Drawer)`
  PaperProps {
    width: 500rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
