import { 
    Box,
    Chip,
    theme
  }
  //@ts-ignore
  from "@enerBit/base"; 
import { useEffect, useState } from "react";

  const StatusChip = ({label, type}) => { 

    const [background, setBackground] = useState("");
    const [color, setColor] = useState("");

    const defineColors = () => 
    {
      let backgroundD = "";
      let colorD = "";
 
      switch (type) {
        case 'warning':
          backgroundD = theme.palette.warning["100"];
          colorD = theme.palette.warning.main;
        break;
        case 'success':
          backgroundD = theme.palette.success["100"];
          colorD = theme.palette.success.main;
        break;
        case 'error':
          backgroundD = theme.palette.error["100"];
          colorD = theme.palette.error.main;
        break;
        case 'info':
          backgroundD = "#E0F2FF";
          colorD = "#3892F3";
        break;
        default:
          backgroundD = "#E0F2FF";
          colorD = "#3892F3";
      }
      setBackground(backgroundD); 
      setColor(colorD); 
    }

    useEffect(() => {
      defineColors();
    }, [])

    return (
      <Chip 
        label={<Box style={{margin: 0, color: color}}>{label}</Box>} 
        sx={{background: background, height: "35px"}}  
      /> 
    );
  };
  
  export default StatusChip;
  