import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import { AddCircleOutlineRoundedIcon } from "../helpers/iconsMaterial";
import { CardProps, ItemProps } from "../models/optionsCard";
import { Theme } from "@emotion/react";
import { SxProps } from "@mui/system";
import { FC } from "react";

// interface Props {
//   arrayOptions: {
//     name: string;
//     position: number;
//     component?: FC<{}>;
//     isTitle?: boolean | null;
//   }[];
//   positionOption;
//   setPositionOption;
//   sx: SxProps<Theme>;
// }

export const OptionsList = ({
  arrayOptions,
  positionOption,
  setPositionOption,
  sx,
}) => {
  return (
    <ContainerOptions>
      {arrayOptions.map((item: ItemProps, index: number) => (
        <div
          onClick={item.isTitle ? null : () => setPositionOption(index)}
          key={index}
        >
          {item.isTitle ? (
            <Typography color="primary" sx={{ ...sx, fontWeight: "400" }}>
              {item.name}
            </Typography>
          ) : (
            <OptionCard isActive={positionOption == index}>
              <ContainerIcon isActive={positionOption == index}>
                <AddCircleOutlineRoundedIcon sx={{ color: "white" }} />
              </ContainerIcon>
              <Typography
                color={positionOption === index ? "secondary" : "primary"}
                fontWeight="bold"
              >
                {item.name}
              </Typography>
            </OptionCard>
          )}
        </div>
      ))}
    </ContainerOptions>
  );
};

//---------------------------Styles-------------------------------//

const ContainerOptions = styled("div")`
  background: #f8f8f8;
  min-width: 354px;
  max-width: 354px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 36px 20px 20px 36px;
  height: 100%;
  @media (max-width: 450px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;

const OptionCard = styled("div")<CardProps>(({ isActive }) => ({
  borderRadius: "16px",
  outline: isActive && "2px solid #53358E",
  cursor: "pointer",
  display: "flex",
  gap: "1rem",
  fontWeight: "bold",
  fontSize: "16px !important",
  justifyContent: "start",
  alignItems: "center",
  background: "#fff",
  minWidth: "266px",
  minHeight: "60px",
  padding: "0 15px",
}));

const ContainerIcon = styled("div")<CardProps>(({ isActive }) => ({
  minWidth: "40px",
  height: "40px",
  borderRadius: "50px",
  background: isActive ? "#ED7004" : "#53358E",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
