import { colors } from "../../styles/figma/theme_colors.ts"; 
  
function addStyle(styleString) {
  const style = document.createElement("style");
  style.textContent = styleString;
  document.head.append(style);
}

addStyle(`
  :root {
    --color-primary: ${colors.primary.main}; 
    --color-secondary: ${colors.warning.main}; 
    --color-neutral700: ${colors.neutral["700"]}; 
    --color-neutral500: ${colors.neutral.main};    
    --color-neutral900: ${colors.neutral["900"]};    
    --color-neutral100: ${colors.neutral["100"]};     
    --color-error500: ${colors.error.main};     
    --color-success: ${colors.success.main};     
    --color-secondary500: ${colors.secondary.orange.main};
  }
`);
 