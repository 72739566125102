import { createTheme, CssBaseline, Theme, ThemeProvider } from "@mui/material";
import { colors } from "../figma/theme_colors";
//@ts-ignore
import HelveticaLTStd from "../../fonts/HelveticaLTStd-Roman.woff2";
import "../../common/helpers/colors";

type ThemeProp = {
  children: JSX.Element;
};

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: React.CSSProperties["color"];
    };
  }

  interface Palette {
    neutral: Palette["primary"];
    secondary_green: Palette["primary"];
    tertiary: Palette["primary"];
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
    secondary_green: PaletteOptions["primary"];
    tertiary: PaletteOptions["primary"];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
  interface ThemeOptions {
    status: {
      danger: React.CSSProperties["color"];
    };
  }
}

export const theme: Theme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: colors.primary[500],
    },
    primary: colors.primary,
    secondary: colors.secondary.orange,
    secondary_green: colors.secondary_green,
    neutral: colors.neutral,
    tertiary: colors.tertiary,
    error: colors.error,
    warning: colors.warning,
    success: colors.success,
    info: colors.information,
  },
  typography: {
    fontFamily: "Helvetica Neue LT Std",
    fontSize: 14,
    h1: {
      fontSize: 84,
      fontWeight: 400,
      lineHeight: "101px",
      letterSpacing: "-0.02em",
    },
    h2: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "60px",
      lineHeight: "72px",
      letterSpacing: "-0.02em",
    },
    h3: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "48px",
      lineHeight: "58px",
      letterSpacing: "-0.02em",
    },
    h4: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "34px",
      lineHeight: "41px",
      letterSpacing: "-0.02em",
    },
    h5: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "29px",
      letterSpacing: "-0.02em",
    },
    h6: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "-0.02em",
    },
    button: { fontSize: 14 },
    subtitle2: { fontSize: 14 },
    //@ts-ignore
    subtitle3: { fontSize: 14 },
    body1: { fontSize: 14 },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Helvetica Neue LT Std';
          src: local('Helvetica Neue LT Std'), local('Helvetica Neue LT Std'), url(${HelveticaLTStd}) format('woff2');
        }
      `,
    },
    MuiButton: {
      defaultProps: {
        style: {
          textTransform: "none",
          borderRadius: "0.8rem",
          fontWeight: "bold",
          padding: "0.6rem 1.1rem",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "1.8rem",
        },
      },
    },
  },
});
//CssBaseline permite que el tema se aplique a todo el sitio

export const ThemeConfig: React.FC<ThemeProp> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
