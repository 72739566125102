import axios from "axios";
import {
	getAcessToken,
	updateAccessToken,
} from "../common/helpers/sessionStorage";
import { refreshToken } from "./refreshToken";

export const api = axios.create({
	baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

api.interceptors.request.use((config) => {
	const accessToken = getAcessToken();
	if (config.headers) {
		config.headers.accept = "application/json";
		config.headers.Authorization = `Bearer ${accessToken}`;
	}
	return config;
});

api.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			const accessToken = await refreshToken();
			updateAccessToken(accessToken);
			axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
			return api(originalRequest);
		}
		return Promise.reject(error);
	},
);

export default api;
