//@ts-ignore
import { EnerbitTheme } from '../../common/models/EnerBitTheme';
import variables from './figma_tokens.json';

const theme: EnerbitTheme = variables;

export const colors = {
    primary: {
        100: theme.global.Primary[100].value,
        300: theme.global.Primary[300].value,
        main: theme.global.Primary[500].value,
        700: theme.global.Primary[700].value,
        900: theme.global.Primary[900].value,
    },
    secondary_green: {
        main: theme.global.Secondary_green[500].value,
    },
    secondary: {
        orange: {
            100: theme.global.Secondary.orange[100].value,
            300: theme.global.Secondary.orange[300].value,
            main: theme.global.Secondary.orange[500].value,
            700: theme.global.Secondary.orange[700].value,
            900: theme.global.Secondary.orange[900].value,
        },
        green: {
            100: theme.global.Secondary.orange[100].value,
            300: theme.global.Secondary.orange[300].value,
            main: theme.global.Secondary.orange[500].value,
            700: theme.global.Secondary.orange[700].value,
            900: theme.global.Secondary.orange[900].value,
        },
        yellow: {
            100: theme.global.Secondary.orange[100].value,
            300: theme.global.Secondary.orange[300].value,
            main: theme.global.Secondary.orange[500].value,
            700: theme.global.Secondary.orange[700].value,
            900: theme.global.Secondary.orange[900].value,
        }
    },
    neutral: {
        100: theme.global.Neutral[100].value,
        300: theme.global.Neutral[300].value,
        main: theme.global.Neutral[500].value,
        700: theme.global.Neutral[700].value,
        900: theme.global.Neutral[900].value,
    },
    tertiary: {
        100: theme.global.Tertiary[100].value,
        300: theme.global.Tertiary[300].value,
        main: theme.global.Tertiary[500].value,
        700: theme.global.Tertiary[700].value,
        900: theme.global.Tertiary[900].value,
    },
    error: {
        100: theme.global.Error[100].value,
        300: theme.global.Error[300].value,
        main: theme.global.Error[500].value,
        700: theme.global.Error[700].value,
        900: theme.global.Error[900].value,
    },
    warning: {
        100: theme.global.Warning[100].value,
        300: theme.global.Warning[300].value,
        main: theme.global.Warning[500].value,
        700: theme.global.Warning[700].value,
        900: theme.global.Warning[900].value,
    },
    success: {
        100: theme.global.Success[100].value,
        300: theme.global.Success[300].value,
        main: theme.global.Success[500].value,
        700: theme.global.Success[700].value,
        900: theme.global.Success[900].value,
    },
    information: {
        100: theme.global.Information[100].value,
        300: theme.global.Information[300].value,
        main: theme.global.Information[500].value,
        700: theme.global.Information[700].value,
        900: theme.global.Information[900].value,
    }
}